import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AddPopularDestination = Loadable(
	lazy(() => import('./AddPopularDestinationDom'))
);
const PopularDestinationList = Loadable(
	lazy(() => import('./PopularDestinationListDom'))
);
const Domlist = Loadable(lazy(() => import('./DomList')));
const AddDomlist = Loadable(lazy(() => import('./AddDomImageSection')));

const PopularDestinationDomRoutes = [
	{
		path: '/dashboard/psdomlist',
		element: <PopularDestinationList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/addpsdom',
		element: <AddPopularDestination />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/domlist',
		element: <Domlist />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/adddomimage',
		element: <AddDomlist />,
		auth: authRoles.admin,
	},
];

export default PopularDestinationDomRoutes;
