import AirlineCustomerCareEditor from 'app/form/AirlineCustomerCareEditor';
import DisclaimerEditor from 'app/form/DisclaimerEditor';
import DomesticFlightEditor from 'app/form/DomesticFlightEditor';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showFootermenuDispatch } from 'reducers/HomeReducer';

const AirlineCustomerCare = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(showFootermenuDispatch());
	}, []);

	return (
		<div>
			<AirlineCustomerCareEditor />
		</div>
	);
};

export default AirlineCustomerCare;
