import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const FlightList = Loadable(lazy(() => import('./BusList')));
const FlightBookingList = Loadable(lazy(() => import('./BusBookingList')));
const BookingDetail = Loadable(lazy(() => import('./BookingDetail')));

const BusRoutes = [
	{
		path: '/dashboard/buslist',
		element: <FlightList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/busbooking',
		element: <FlightBookingList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/busbooking/busdetail',
		element: <BookingDetail />,
		auth: authRoles.admin,
	},
];

export default BusRoutes;
