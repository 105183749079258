import AirportIndiaEditor from 'app/form/AirportIndiaEditor';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showFootermenuDispatch } from 'reducers/HomeReducer';

const AirportIndia = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(showFootermenuDispatch());
	}, []);
	return (
		<div>
			<AirportIndiaEditor />
		</div>
	);
};

export default AirportIndia;
