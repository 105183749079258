import InternationalAirlineEditor from 'app/form/InternationalAirlineEditor';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showFootermenuDispatch } from 'reducers/HomeReducer';

const InternationalAirline = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(showFootermenuDispatch());
	}, []);
	return (
		<div>
			<InternationalAirlineEditor />
		</div>
	);
};

export default InternationalAirline;
