import DefaultMarkupForm from 'app/form/DefaultMarkupForm';
import Loader from 'app/form/Loader';
import { useSelector } from 'react-redux';
import MarkupForm from 'app/form/MarkupForm';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showManageMarkupDispatch } from 'reducers/HomeReducer';

const DefaultMarkup = () => {
	const dispatch = useDispatch();
	const { loading } = useSelector((state) => state.home);
	useEffect(() => {
		let form = {
			agentId: 0,
		};
		dispatch(showManageMarkupDispatch(form));
	}, []);
	return (
		<div>
			<DefaultMarkupForm />
			{loading ? <Loader /> : ''}
		</div>
	);
};

export default DefaultMarkup;
