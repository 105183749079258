import DisclaimerEditor from 'app/form/DisclaimerEditor';
import DomesticBusRoutesEditor from 'app/form/DomesticBusRoutesEditor';
import DomesticFlightEditor from 'app/form/DomesticFlightEditor';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showFootermenuDispatch } from 'reducers/HomeReducer';

const DomesticBusRoutes = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(showFootermenuDispatch());
	}, []);

	return (
		<div>
			<DomesticBusRoutesEditor />
		</div>
	);
};

export default DomesticBusRoutes;
