import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const SeoFlight = Loadable(lazy(() => import('./SeoFlight')));
const SeoBus = Loadable(lazy(() => import('./SeoBus')));
const SeoHolidays = Loadable(lazy(() => import('./SeoHolidays')));
const SeoHotel = Loadable(lazy(() => import('./SeoHotel')));

const SeoRoutes = [
	{
		path: '/dashboard/seoflight',
		element: <SeoFlight />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/seobus',
		element: <SeoBus />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/seoholidays',
		element: <SeoHolidays />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/seohotel',
		element: <SeoHotel />,
		auth: authRoles.admin,
	},
];

export default SeoRoutes;
