import InternationalAirlineEditor from 'app/form/InternationalAirlineEditor';
import InternationalFlightEditor from 'app/form/InternationalFlightEditor';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showFootermenuDispatch } from 'reducers/HomeReducer';

const InternationalFlight = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(showFootermenuDispatch());
	}, []);
	return (
		<div>
			<InternationalFlightEditor />
		</div>
	);
};

export default InternationalFlight;
